<template>
  <v-container class="pt-0 pb-15">
    <div style="display: flex; flex-drection: row; align-items: center;">
      <span class="agency-title">Donations</span>
      <div class="title-line ml-3" />
    </div>
    <v-data-table
      :headers="headers"
      :items="users"
      :items-per-page="15"
      :loading="loading"
      @click:row="onSelectClient"
      class="elevation-0"
    >
      <template v-slot:[`item.image`]="{ item }">
        <v-avatar class="elevation-6 my-3 ml-5">
          <v-img :src="item.image ? item.image : placeholderImage" />
        </v-avatar>
      </template>
    </v-data-table>
  </v-container>
</template>
<script>
export default {
  data() {
    return {
      users: [
        {
          email: "jkrugrenas@gmail.com",
          date: "Oct 2, 2020",
          name: "Jaimse Smith",
          price: "$980"
        },
        {
          email: "jkrugrenas@gmail.com",
          date: "Oct 2, 2020",
          name: "Jaimse Smith",
          price: "$980"
        },
        {
          email: "jkrugrenas@gmail.com",
          date: "Oct 2, 2020",
          name: "Jaimse Smith",
          price: "$980"
        },
        {
          email: "jkrugrenas@gmail.com",
          date: "Oct 2, 2020",
          name: "Jaimse Smith",
          price: "$980"
        },
        {
          email: "jkrugrenas@gmail.com",
          date: "Oct 2, 2020",
          name: "Jaimse Smith",
          price: "$980"
        },
        {
          email: "jkrugrenas@gmail.com",
          date: "Oct 2, 2020",
          name: "Jaimse Smith",
          price: "$980"
        },
        {
          email: "jkrugrenas@gmail.com",
          date: "Oct 2, 2020",
          name: "Jaimse Smith",
          price: "$980"
        }
      ],
      headers: [
        { text: "Email", value: "email" },
        { text: "Date", value: "date" },
        { text: "Name", value: "name" },
        { text: "Price", value: "price" }
      ]
    };
  },
  methods: {
    onSelectEvent(ad) {
      console.log(ad);
    }
  }
};
</script>
<style scoped>
.agency-title {
  color: #472583;
  font-size: 50px;
  font-weight: bold;
}
.title-line {
  height: 2px;
  width: 100%;
  background-color: #472583;
}
.agency-scroll-section {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
}

::-webkit-scrollbar {
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px light-grey;
  border-radius: 4px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #feb934;
  border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #f5bd4d;
}

.product-title {
  font-size: 28px;
  color: #472583;
  font-weight: 500;
}
.category-title {
  font-size: 32px;
  color: #472583;
  font-weight: 500;
}
.price-text {
  font-size: 18px;
  color: #472583;
  font-weight: 500;
}
.agency-scroll-section {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
}
</style>
